/*----------------------------------------------*\
        $Buttons
\*----------------------------------------------*/

.call-to-action {
    font-weight: bold;
    padding: 0 2rem;
    line-height: 50px;
    height: 50px;
    //box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05) !important;
    &:hover,
    &:focus,
    &:active {
        //box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15) !important;
    }


    @media (max-width: $mq-mobile) {
        font-size: 11px;
    }


    @media (max-width: $mq-tablet) {
        height: 36px;
        line-height: 36px;
    }

    &--small {
        @extend .call-to-action;
        height: 36px;
        line-height: 36px;
    }
}

.social-button {
    width: 4rem;
    height: 4rem;
    flex-shrink: 0;
    border-radius: 50%;
    line-height: 4rem;

    i {
        vertical-align: sub;
    }
    
    &--small {
        @extend .social-button;
        width: 3rem;
        height: 3rem;
        line-height: 3rem;

        i {
            font-size: 1.5rem;
        }
    }
}
