/*----------------------------------------------*\
        $Responsive images
\*----------------------------------------------*/

.media-cover-wrapper {
    position: relative;
    overflow: hidden;
    z-index: 1;
    .media-cover {
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
