/*----------------------------------------------*\
        $Colors
\*----------------------------------------------*/

.facebook-color {
    background-color: #3B5998;
    &:hover {
        background-color: #5872A7;
    }
}

.linkedin-color {
    background-color: #0077B5;
    &:hover {
        background-color: #268BC0;
    }
}

.youtube-color {
    background-color: #CD201F;
    &:hover {
        background-color: #D54141;
    }
}

.pmb-duck {
    background-color: #00a096 !important;

    &.lighten-5 {
        background-color: #e0f4f2 !important;
    }
    &.lighten-4 {
        background-color: #b3e3e0 !important;
    }
    &.lighten-3 {
        background-color: #80d0cb !important;
    }
    &.lighten-2 {
        background-color: #4dbdb6 !important;
    }
    &.lighten-1 {
        background-color: #26aea6 !important;
    }
    &.darken-1 {
        background-color: #00988e !important;
    }
    &.darken-2 {
        background-color: #008e83 !important;
    }
    &.darken-3 {
        background-color: #008479 !important;
    }
    &.darken-4 {
        background-color: #007368 !important;
    }
    &.accent-1 {
        background-color: #a1fff4 !important;
    }
    &.accent-2 {
        background-color: #6effee !important;
    }
    &.accent-3 {
        background-color: #3bffe8 !important;
    }
    &.accent-4 {
        background-color: #22ffe4 !important;
    }
}

.pmb-duck-text {
    color: #00a096 !important;

    &.text-lighten-5 {
        color: #e0f4f2 !important;
    }
    &.text-lighten-4 {
        color: #b3e3e0 !important;
    }
    &.text-lighten-3 {
        color: #80d0cb !important;
    }
    &.text-lighten-2 {
        color: #4dbdb6 !important;
    }
    &.text-lighten-1 {
        color: #26aea6 !important;
    }
    &.text-darken-1 {
        color: #00988e !important;
    }
    &.text-darken-2 {
        color: #008e83 !important;
    }
    &.text-darken-3 {
        color: #008479 !important;
    }
    &.text-darken-4 {
        color: #007368 !important;
    }
    &.text-accent-1 {
        color: #a1fff4 !important;
    }
    &.text-accent-2 {
        color: #6effee !important;
    }
    &.text-accent-3 {
        color: #3bffe8 !important;
    }
    &.text-accent-4 {
        color: #22ffe4 !important;
    }
}

.pmb-green {
    background-color: #97bf0d !important;

    &.lighten-5 {
        background-color: #f3f7e2 !important;
    }
    &.lighten-4 {
        background-color: #e0ecb6 !important;
    }
    &.lighten-3 {
        background-color: #cbdf86 !important;
    }
    &.lighten-2 {
        background-color: #b6d256 !important;
    }
    &.lighten-1 {
        background-color: #a7c931 !important;
    }
    &.darken-1 {
        background-color: #8fb90b !important;
    }
    &.darken-2 {
        background-color: #84b109 !important;
    }
    &.darken-3 {
        background-color: #7aa907 !important;
    }
    &.darken-4 {
        background-color: #699b03 !important;
    }
    &.accent-1 {
        background-color: #e9ffc6 !important;
    }
    &.accent-2 {
        background-color: #d6ff93 !important;
    }
    &.accent-3 {
        background-color: #c3ff60 !important;
    }
    &.accent-4 {
        background-color: #b9ff47 !important;
    }
}

.pmb-green-text {
    color: #97bf0d !important;
    
    &.text-lighten-5 {
        color: #f3f7e2 !important;
    }
    &.text-lighten-4 {
        color: #e0ecb6 !important;
    }
    &.text-lighten-3 {
        color: #cbdf86 !important;
    }
    &.text-lighten-2 {
        color: #b6d256 !important;
    }
    &.text-lighten-1 {
        color: #a7c931 !important;
    }
    &.text-darken-1 {
        color: #8fb90b !important;
    }
    &.text-darken-2 {
        color: #84b109 !important;
    }
    &.text-darken-3 {
        color: #7aa907 !important;
    }
    &.text-darken-4 {
        color: #699b03 !important;
    }
    &.text-accent-1 {
        color: #e9ffc6 !important;
    }
    &.text-accent-2 {
        color: #d6ff93 !important;
    }
    &.text-accent-3 {
        color: #c3ff60 !important;
    }
    &.text-accent-4 {
        color: #b9ff47 !important;
    }
}
