/*----------------------------------------------*\
        $Helpers
\*----------------------------------------------*/

.position-relative {
    position: relative !important;
}

.margin-auto {
    margin: auto !important;
}

.p-10 {
    padding: 6rem !important;
}

.px-10 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
}

.py-10 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.white-space-pre {
    white-space: pre !important;
}

.white-space-pre-line {
    white-space: pre-line !important;
}

.white-space-nowrap {
    white-space: nowrap !important;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
}

.vertical-align-middle {
    vertical-align: middle !important;
}

.font-weight-semi-bold {
    font-weight: 600;
}
