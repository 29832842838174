/*----------------------------------------------*\
        $Sections
\*----------------------------------------------*/

section {
    background-color: transparent;
    background-color: rgba(255, 255, 255, 0);
}


/*----------------------------------------------*\
        $Card style
\*----------------------------------------------*/

.card-style {
    transition: box-shadow 0.25s;
    background-color: white; //border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 2px; //box-shadow: 0px 4px 10px 0px rgba(230, 230, 230, 1);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05) !important; //box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}




/*----------------------------------------------*\
        $Diagonal separator
\*----------------------------------------------*/

.diagonal-separator {
    @media (min-width: $mq-desktop) {
        position: relative;
        overflow: hidden;
    }
}

.diagonal-separator-one {
    @media (min-width: $mq-desktop) {
        background: linear-gradient(to right, #212121 20%, white 20%); //grey darken-4
    }
}

.diagonal-separator-two {
    @media (min-width: $mq-desktop) {
        background: linear-gradient(to right, #eeeeee 20%, white 20%); //grey lighten-3
    }
}

.diagonal-separator {
    @media (min-width: $mq-desktop) {
        article:first-child {
            position: relative;
            float: left;
            width: calc(50% - 100px - 3rem);
        }
        &:first-child article:last-child {
            background-color: white;
        }
    }
}

.diagonal {
    @media (min-width: $mq-desktop) {
        position: relative;
        z-index: 1;
        &:after {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 100px;
            right: 0;
            transform: skewX(-15deg) rotate(180deg);
            width: 100%;
            height: 100%;
            content: "";
        }
    }
}

.diagonal-one {
    background-color: #212121; //grey darken-4
    @media (min-width: $mq-desktop) {
        &:after {
            background-color: #212121; //grey darken-4
        }
    }
}

.diagonal-two {
    background-color: #eeeeee; //grey lighten-3
    @media (min-width: $mq-desktop) {
        &:after {
            background-color: #eeeeee; //grey lighten-3
        }
    }
}

.diagonal-separator article:last-child {
    @media (min-width: $mq-desktop) {
        position: relative;
        float: right;
        width: calc(50% - 100px);
    }
}




/*----------------------------------------------*\
        $Navigation
\*----------------------------------------------*/

body {
    padding-top: 56px;
    @media (min-width: 601px) {
        padding-top: 64px;
    }
}




/*----------------------------------------------*\
        $Tags
\*----------------------------------------------*/

.tags .chip {
    padding: 0 1rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    line-height: 2;
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: color 0.3s ease, background-color 0.3s ease;
    &:hover,
    &:focus,
    &:active {
        color: white !important;
        background-color: rgba(255, 255, 255, 0.1);
    }
}




/*----------------------------------------------*\
        $Pricing tables
\*----------------------------------------------*/


/* STYLE 1 */

.pricing {
    &.style-1 .price,
    &.style-2 .plan-title {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        display: table;
        width: calc(100% + 1px);
        h5 {
            display: table-cell;
            margin: 0;
            padding: 1rem;
            height: 5rem;
            line-height: 1.2;
            vertical-align: middle;
        }
    }
    &.style-3 {
        .plan-title {
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            padding: 10px 0;
        }
        .price {
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
        }
        .plan-signup-btn {
            position: absolute;
            bottom: 80px;
            right: 20px;
        }
    }
    .price {
        padding: 15px 0;
        h4 {
            text-align: center;
            line-height: 1;
        }
    }
    .plan-title h5 {
        text-align: center;
    }
    .price span {
        display: inline-block;
        position: relative;
    }
    .plan-title span {
        display: inline-block;
        position: relative;
        &.plan {
            font-size: 25px;
            font-weight: 200;
        }
    }
    .price span {
        &.amount {
            font-size: 50px;
            font-weight: 100;
        }
        &.period {
            margin-left: -10px;
        }
        &.currency {
            letter-spacing: -6px;
            top: -20px;
            font-size: 16px;
        }
        &.period {
            font-size: 16px;
        }
    }
    .collection li.collection-item:hover {
        background: #f5f5f5;
    }
}



/*----------------------------------------------*\
        $Stylized unordered list
\*----------------------------------------------*/

.stylized-unordered-list {

    li {
        position: relative;
        list-style: none;
        margin: 1em auto;
        padding-left: 35px;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 11px;
            width: 20px;
            height: 2px;
            background-color: black;
        }
    }
    &.red-list-style.list-style-accent-2 {
        li:before {
            background-color: #ff5252;
        }
    }
    &.blue-list-style.list-style-accent-2 {
        li:before {
            background-color: #448aff;
        }
    }
    &.light-green-list-style {
        li:before {
            background-color: #76ff03;
        }
    }
}




/*----------------------------------------------*\
        $Revealer
\*----------------------------------------------*/

.revealer {
    li {
        cursor: pointer;
        &.is-active {
            background-color: #eee;
        }

        & * {
            pointer-events: none;
        }
    }
}