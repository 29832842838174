/*----------------------------------------------*\
        $Typography
\*----------------------------------------------*/

html,
body {
    -webkit-font-smoothing: antialiased;
}

html,
body,
a {
    font-family: 'Lato', -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
    margin-top: 0;
    margin-bottom: 2vh;
}

h1, .h1 {
    //font-size: 50px;
    $map: (768px: 35px, 1200px: 45px);
    @include poly-fluid-sizing('font-size', $map);
    text-transform: uppercase;

    @media (min-width: $mq-large-desktop) {
        font-size: 45px !important;
    }
}

h2, .h2 {
    //font-size: 40px;
    $map: (576px: 30px, 1200px: 40px);
    @include poly-fluid-sizing('font-size', $map);
    font-weight: 300;
    color: #008479;

    @media (min-width: $mq-large-desktop) {
        font-size: 40px !important;
    }
}

h3, .h3 {
    //font-size: 20px;
    $map: (576px: 18px, 992px: 20px);
    @include poly-fluid-sizing('font-size', $map);

    @media (min-width: $mq-desktop) {
        font-size: 20px !important;
    }
}

p {
    $map: (576px: 16px, 992px: 18px);
    @include poly-fluid-sizing('font-size', $map);
    margin-bottom: 2vh;
    text-align: justify;
    line-height: 1.75;
    word-wrap: break-word;
    hyphens: auto;
    color: #9e9e9e;

    &.footer-text {
        font-size: 1rem;
    }
}

/*h2, .h2 {
    //font-size: 30px;
    $map: (576px: 24px, 768px: 30px, 992px: 30px, 1200px: 34px);
    @include poly-fluid-sizing('font-size', $map);
    font-weight: 300;
    text-transform: uppercase;
}

h3, .h3 {
    //font-size: 24px;
    $map: (576px: 19.2px, 768px: 24px, 992px: 24px, 1200px: 27.2px);
    @include poly-fluid-sizing('font-size', $map);
    font-weight: 300;
}

h4, .h4 {
    //font-size: 20px;
    $map: (576px: 16px, 768px: 20px, 992px: 20px, 1200px: 22.67px);
    @include poly-fluid-sizing('font-size', $map);
}

h5, .h5 {
    //font-size: 18px;
    $map: (576px: 14.4px, 768px: 18px, 992px: 18px, 1200px: 20.4px);
    @include poly-fluid-sizing('font-size', $map);
}

p {
    word-wrap: break-word;
    hyphens: auto;
    color: #9e9e9e;
    //grey
}*/
