/*----------------------------------------------*\
        $BASE
\*----------------------------------------------*/
/*------------------------------------*\
        $Media queries
\*------------------------------------*/
@font-face {
  font-family: 'Be-in-home';
  src: url("../../fonts/Be-in-home/fonts/Be-in-home.eot?93y49h");
  src: url("../../fonts/Be-in-home/fonts/Be-in-home.eot?93y49h#iefix") format("embedded-opentype"), url("../../fonts/Be-in-home/fonts/Be-in-home.ttf?93y49h") format("truetype"), url("../../fonts/Be-in-home/fonts/Be-in-home.woff?93y49h") format("woff"), url("../../fonts/Be-in-home/fonts/Be-in-home.svg?93y49h#Be-in-home") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="bih-"],
[class*=" bih-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Be-in-home' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.bih-washer:before {
  content: "\e939"; }

.bih-mezzanine:before {
  content: "\e93a"; }

.bih-hanger:before {
  content: "\e93b"; }

.bih-full-cog:before {
  content: "\e919"; }

.bih-contrariwise-arrows:before {
  content: "\e91a"; }

.bih-fireplace:before {
  content: "\e91b"; }

.bih-firewood:before {
  content: "\e91c"; }

.bih-fuel-box:before {
  content: "\e91d"; }

.bih-full-box:before {
  content: "\e934"; }

.bih-pv-panel:before {
  content: "\e935"; }

.bih-tap:before {
  content: "\e938"; }

.bih-homes:before {
  content: "\e918"; }

.bih-home:before {
  content: "\e917"; }

.bih-constructor:before {
  content: "\e933"; }

.bih-owner:before {
  content: "\e936"; }

.bih-tenant:before {
  content: "\e937"; }

.bih-key-house:before {
  content: "\e916"; }

.bih-attic:before {
  content: "\e900"; }

.bih-baby:before {
  content: "\e901"; }

.bih-bathroom:before {
  content: "\e902"; }

.bih-bedroom:before {
  content: "\e903"; }

.bih-bell:before {
  content: "\e904"; }

.bih-box:before {
  content: "\e905"; }

.bih-calendar:before {
  content: "\e906"; }

.bih-catchphrase:before {
  content: "\e907"; }

.bih-cellar:before {
  content: "\e908"; }

.bih-constructor-bubble:before {
  content: "\e909"; }

.bih-dinning-room:before {
  content: "\e90a"; }

.bih-entrance:before {
  content: "\e90b"; }

.bih-eyed-bubbles:before {
  content: "\e90c"; }

.bih-fan:before {
  content: "\e90d"; }

.bih-file:before {
  content: "\e90e"; }

.bih-fire-alarm:before {
  content: "\e90f"; }

.bih-flame:before {
  content: "\e910"; }

.bih-garage:before {
  content: "\e911"; }

.bih-garden:before {
  content: "\e912"; }

.bih-hi-fi:before {
  content: "\e913"; }

.bih-house-cog:before {
  content: "\e914"; }

.bih-house-wrench:before {
  content: "\e915"; }

.bih-kitchen:before {
  content: "\e91e"; }

.bih-lightbulb:before {
  content: "\e91f"; }

.bih-living-room:before {
  content: "\e920"; }

.bih-loft:before {
  content: "\e921"; }

.bih-logo:before {
  content: "\e922"; }

.bih-office:before {
  content: "\e923"; }

.bih-picture:before {
  content: "\e924"; }

.bih-pictures-and-documents:before {
  content: "\e925"; }

.bih-plug:before {
  content: "\e926"; }

.bih-plug-box:before {
  content: "\e927"; }

.bih-recycle:before {
  content: "\e928"; }

.bih-shower-room:before {
  content: "\e929"; }

.bih-snowflake:before {
  content: "\e92a"; }

.bih-tachometer-box:before {
  content: "\e92b"; }

.bih-thermometer:before {
  content: "\e92c"; }

.bih-trash-can:before {
  content: "\e92d"; }

.bih-water-closet:before {
  content: "\e92e"; }

.bih-water-drop:before {
  content: "\e92f"; }

.bih-wifi:before {
  content: "\e930"; }

.bih-worker-wrench:before {
  content: "\e931"; }

.bih-wrench:before {
  content: "\e932"; }

/*----------------------------------------------*\
        $BASE
\*----------------------------------------------*/
*:focus {
  outline: none !important; }

html,
body {
  height: 100%;
  width: 100%;
  margin: 0; }

body {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden; }

strong {
  font-weight: bold; }

a {
  color: currentColor;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease; }
  a:visited, a:hover, a:focus, a:active {
    color: currentColor;
    text-decoration: none;
    transition: color 0.3s ease, background-color 0.3s ease; }

i {
  font-style: normal; }

button,
input,
optgroup,
select,
textarea,
label {
  font-family: 'Roboto', sans-serif; }

button,
input,
optgroup,
select,
textarea {
  color: initial; }

figure {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  margin: 0; }

/* Remove controls from Firefox */
input[type=number] {
  -moz-appearance: textfield; }
  input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
    /* Removes leftover margin */ }

/* Remove controls from Safari and Chrome */
/* Opera right align fix */
html:not([dir="rtl"]) input {
  text-align: left; }

ul {
  margin: 0;
  color: #7b7b7b; }

ul.browser-default {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 20px; }

address {
  color: #7b7b7b; }

/*----------------------------------------------*\
        $MIXINS
\*----------------------------------------------*/
/*----------------------------------------------*\
        $Helpers
\*----------------------------------------------*/
.position-relative {
  position: relative !important; }

.margin-auto {
  margin: auto !important; }

.p-10 {
  padding: 6rem !important; }

.px-10 {
  padding-left: 6rem !important;
  padding-right: 6rem !important; }

.py-10 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.white-space-pre {
  white-space: pre !important; }

.white-space-pre-line {
  white-space: pre-line !important; }

.white-space-nowrap {
  white-space: nowrap !important;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word; }

.vertical-align-middle {
  vertical-align: middle !important; }

.font-weight-semi-bold {
  font-weight: 600; }

/*----------------------------------------------*\
        $Colors
\*----------------------------------------------*/
.facebook-color {
  background-color: #3B5998; }
  .facebook-color:hover {
    background-color: #5872A7; }

.linkedin-color {
  background-color: #0077B5; }
  .linkedin-color:hover {
    background-color: #268BC0; }

.youtube-color {
  background-color: #CD201F; }
  .youtube-color:hover {
    background-color: #D54141; }

.pmb-duck {
  background-color: #00a096 !important; }
  .pmb-duck.lighten-5 {
    background-color: #e0f4f2 !important; }
  .pmb-duck.lighten-4 {
    background-color: #b3e3e0 !important; }
  .pmb-duck.lighten-3 {
    background-color: #80d0cb !important; }
  .pmb-duck.lighten-2 {
    background-color: #4dbdb6 !important; }
  .pmb-duck.lighten-1 {
    background-color: #26aea6 !important; }
  .pmb-duck.darken-1 {
    background-color: #00988e !important; }
  .pmb-duck.darken-2 {
    background-color: #008e83 !important; }
  .pmb-duck.darken-3 {
    background-color: #008479 !important; }
  .pmb-duck.darken-4 {
    background-color: #007368 !important; }
  .pmb-duck.accent-1 {
    background-color: #a1fff4 !important; }
  .pmb-duck.accent-2 {
    background-color: #6effee !important; }
  .pmb-duck.accent-3 {
    background-color: #3bffe8 !important; }
  .pmb-duck.accent-4 {
    background-color: #22ffe4 !important; }

.pmb-duck-text {
  color: #00a096 !important; }
  .pmb-duck-text.text-lighten-5 {
    color: #e0f4f2 !important; }
  .pmb-duck-text.text-lighten-4 {
    color: #b3e3e0 !important; }
  .pmb-duck-text.text-lighten-3 {
    color: #80d0cb !important; }
  .pmb-duck-text.text-lighten-2 {
    color: #4dbdb6 !important; }
  .pmb-duck-text.text-lighten-1 {
    color: #26aea6 !important; }
  .pmb-duck-text.text-darken-1 {
    color: #00988e !important; }
  .pmb-duck-text.text-darken-2 {
    color: #008e83 !important; }
  .pmb-duck-text.text-darken-3 {
    color: #008479 !important; }
  .pmb-duck-text.text-darken-4 {
    color: #007368 !important; }
  .pmb-duck-text.text-accent-1 {
    color: #a1fff4 !important; }
  .pmb-duck-text.text-accent-2 {
    color: #6effee !important; }
  .pmb-duck-text.text-accent-3 {
    color: #3bffe8 !important; }
  .pmb-duck-text.text-accent-4 {
    color: #22ffe4 !important; }

.pmb-green {
  background-color: #97bf0d !important; }
  .pmb-green.lighten-5 {
    background-color: #f3f7e2 !important; }
  .pmb-green.lighten-4 {
    background-color: #e0ecb6 !important; }
  .pmb-green.lighten-3 {
    background-color: #cbdf86 !important; }
  .pmb-green.lighten-2 {
    background-color: #b6d256 !important; }
  .pmb-green.lighten-1 {
    background-color: #a7c931 !important; }
  .pmb-green.darken-1 {
    background-color: #8fb90b !important; }
  .pmb-green.darken-2 {
    background-color: #84b109 !important; }
  .pmb-green.darken-3 {
    background-color: #7aa907 !important; }
  .pmb-green.darken-4 {
    background-color: #699b03 !important; }
  .pmb-green.accent-1 {
    background-color: #e9ffc6 !important; }
  .pmb-green.accent-2 {
    background-color: #d6ff93 !important; }
  .pmb-green.accent-3 {
    background-color: #c3ff60 !important; }
  .pmb-green.accent-4 {
    background-color: #b9ff47 !important; }

.pmb-green-text {
  color: #97bf0d !important; }
  .pmb-green-text.text-lighten-5 {
    color: #f3f7e2 !important; }
  .pmb-green-text.text-lighten-4 {
    color: #e0ecb6 !important; }
  .pmb-green-text.text-lighten-3 {
    color: #cbdf86 !important; }
  .pmb-green-text.text-lighten-2 {
    color: #b6d256 !important; }
  .pmb-green-text.text-lighten-1 {
    color: #a7c931 !important; }
  .pmb-green-text.text-darken-1 {
    color: #8fb90b !important; }
  .pmb-green-text.text-darken-2 {
    color: #84b109 !important; }
  .pmb-green-text.text-darken-3 {
    color: #7aa907 !important; }
  .pmb-green-text.text-darken-4 {
    color: #699b03 !important; }
  .pmb-green-text.text-accent-1 {
    color: #e9ffc6 !important; }
  .pmb-green-text.text-accent-2 {
    color: #d6ff93 !important; }
  .pmb-green-text.text-accent-3 {
    color: #c3ff60 !important; }
  .pmb-green-text.text-accent-4 {
    color: #b9ff47 !important; }

/*----------------------------------------------*\
        $Typography
\*----------------------------------------------*/
html,
body {
  -webkit-font-smoothing: antialiased; }

html,
body,
a {
  font-family: 'Lato', -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
  margin-top: 0;
  margin-bottom: 2vh; }

h1, .h1 {
  font-size: 35px;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: calc(2.31481vw + 17.22222px); } }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 45px; } }
  @media (min-width: 75em) {
    h1, .h1 {
      font-size: 45px !important; } }

h2, .h2 {
  font-size: 30px;
  font-weight: 300;
  color: #008479; }
  @media (min-width: 576px) {
    h2, .h2 {
      font-size: calc(1.60256vw + 20.76923px); } }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 40px; } }
  @media (min-width: 75em) {
    h2, .h2 {
      font-size: 40px !important; } }

h3, .h3 {
  font-size: 18px; }
  @media (min-width: 576px) {
    h3, .h3 {
      font-size: calc(0.48077vw + 15.23077px); } }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 20px; } }
  @media (min-width: 62em) {
    h3, .h3 {
      font-size: 20px !important; } }

p {
  font-size: 16px;
  margin-bottom: 2vh;
  text-align: justify;
  line-height: 1.75;
  word-wrap: break-word;
  hyphens: auto;
  color: #9e9e9e; }
  @media (min-width: 576px) {
    p {
      font-size: calc(0.48077vw + 13.23077px); } }
  @media (min-width: 992px) {
    p {
      font-size: 18px; } }
  p.footer-text {
    font-size: 1rem; }

/*h2, .h2 {
    //font-size: 30px;
    $map: (576px: 24px, 768px: 30px, 992px: 30px, 1200px: 34px);
    @include poly-fluid-sizing('font-size', $map);
    font-weight: 300;
    text-transform: uppercase;
}

h3, .h3 {
    //font-size: 24px;
    $map: (576px: 19.2px, 768px: 24px, 992px: 24px, 1200px: 27.2px);
    @include poly-fluid-sizing('font-size', $map);
    font-weight: 300;
}

h4, .h4 {
    //font-size: 20px;
    $map: (576px: 16px, 768px: 20px, 992px: 20px, 1200px: 22.67px);
    @include poly-fluid-sizing('font-size', $map);
}

h5, .h5 {
    //font-size: 18px;
    $map: (576px: 14.4px, 768px: 18px, 992px: 18px, 1200px: 20.4px);
    @include poly-fluid-sizing('font-size', $map);
}

p {
    word-wrap: break-word;
    hyphens: auto;
    color: #9e9e9e;
    //grey
}*/
/*----------------------------------------------*\
        $Buttons
\*----------------------------------------------*/
.call-to-action, .call-to-action--small {
  font-weight: bold;
  padding: 0 2rem;
  line-height: 50px;
  height: 50px; }
  @media (max-width: 36em) {
    .call-to-action, .call-to-action--small {
      font-size: 11px; } }
  @media (max-width: 48em) {
    .call-to-action, .call-to-action--small {
      height: 36px;
      line-height: 36px; } }
  .call-to-action--small {
    height: 36px;
    line-height: 36px; }

.social-button, .social-button--small {
  width: 4rem;
  height: 4rem;
  flex-shrink: 0;
  border-radius: 50%;
  line-height: 4rem; }
  .social-button i, .social-button--small i {
    vertical-align: sub; }
  .social-button--small {
    width: 3rem;
    height: 3rem;
    line-height: 3rem; }
    .social-button--small i {
      font-size: 1.5rem; }

/*----------------------------------------------*\
        $Responsive images
\*----------------------------------------------*/
.media-cover-wrapper {
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .media-cover-wrapper .media-cover {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

label.required:after {
  content: "*";
  opacity: 0.5; }

/*----------------------------------------------*\
        $Sections
\*----------------------------------------------*/
section {
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0); }

/*----------------------------------------------*\
        $Card style
\*----------------------------------------------*/
.card-style {
  transition: box-shadow 0.25s;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05) !important; }

/*----------------------------------------------*\
        $Diagonal separator
\*----------------------------------------------*/
@media (min-width: 62em) {
  .diagonal-separator {
    position: relative;
    overflow: hidden; } }

@media (min-width: 62em) {
  .diagonal-separator-one {
    background: linear-gradient(to right, #212121 20%, white 20%); } }

@media (min-width: 62em) {
  .diagonal-separator-two {
    background: linear-gradient(to right, #eeeeee 20%, white 20%); } }

@media (min-width: 62em) {
  .diagonal-separator article:first-child {
    position: relative;
    float: left;
    width: calc(50% - 100px - 3rem); }
  .diagonal-separator:first-child article:last-child {
    background-color: white; } }

@media (min-width: 62em) {
  .diagonal {
    position: relative;
    z-index: 1; }
    .diagonal:after {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 100px;
      right: 0;
      transform: skewX(-15deg) rotate(180deg);
      width: 100%;
      height: 100%;
      content: ""; } }

.diagonal-one {
  background-color: #212121; }
  @media (min-width: 62em) {
    .diagonal-one:after {
      background-color: #212121; } }

.diagonal-two {
  background-color: #eeeeee; }
  @media (min-width: 62em) {
    .diagonal-two:after {
      background-color: #eeeeee; } }

@media (min-width: 62em) {
  .diagonal-separator article:last-child {
    position: relative;
    float: right;
    width: calc(50% - 100px); } }

/*----------------------------------------------*\
        $Navigation
\*----------------------------------------------*/
body {
  padding-top: 56px; }
  @media (min-width: 601px) {
    body {
      padding-top: 64px; } }

/*----------------------------------------------*\
        $Tags
\*----------------------------------------------*/
.tags .chip {
  padding: 0 1rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 2;
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: color 0.3s ease, background-color 0.3s ease; }
  .tags .chip:hover, .tags .chip:focus, .tags .chip:active {
    color: white !important;
    background-color: rgba(255, 255, 255, 0.1); }

/*----------------------------------------------*\
        $Pricing tables
\*----------------------------------------------*/
/* STYLE 1 */
.pricing.style-1 .price,
.pricing.style-2 .plan-title {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  display: table;
  width: calc(100% + 1px); }
  .pricing.style-1 .price h5,
  .pricing.style-2 .plan-title h5 {
    display: table-cell;
    margin: 0;
    padding: 1rem;
    height: 5rem;
    line-height: 1.2;
    vertical-align: middle; }

.pricing.style-3 .plan-title {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 10px 0; }

.pricing.style-3 .price {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; }

.pricing.style-3 .plan-signup-btn {
  position: absolute;
  bottom: 80px;
  right: 20px; }

.pricing .price {
  padding: 15px 0; }
  .pricing .price h4 {
    text-align: center;
    line-height: 1; }

.pricing .plan-title h5 {
  text-align: center; }

.pricing .price span {
  display: inline-block;
  position: relative; }

.pricing .plan-title span {
  display: inline-block;
  position: relative; }
  .pricing .plan-title span.plan {
    font-size: 25px;
    font-weight: 200; }

.pricing .price span.amount {
  font-size: 50px;
  font-weight: 100; }

.pricing .price span.period {
  margin-left: -10px; }

.pricing .price span.currency {
  letter-spacing: -6px;
  top: -20px;
  font-size: 16px; }

.pricing .price span.period {
  font-size: 16px; }

.pricing .collection li.collection-item:hover {
  background: #f5f5f5; }

/*----------------------------------------------*\
        $Stylized unordered list
\*----------------------------------------------*/
.stylized-unordered-list li {
  position: relative;
  list-style: none;
  margin: 1em auto;
  padding-left: 35px; }
  .stylized-unordered-list li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 11px;
    width: 20px;
    height: 2px;
    background-color: black; }

.stylized-unordered-list.red-list-style.list-style-accent-2 li:before {
  background-color: #ff5252; }

.stylized-unordered-list.blue-list-style.list-style-accent-2 li:before {
  background-color: #448aff; }

.stylized-unordered-list.light-green-list-style li:before {
  background-color: #76ff03; }

/*----------------------------------------------*\
        $Revealer
\*----------------------------------------------*/
.revealer li {
  cursor: pointer; }
  .revealer li.is-active {
    background-color: #eee; }
  .revealer li * {
    pointer-events: none; }

/*----------------------------------------------*\
        $Override Materialize
\*----------------------------------------------*/
.btn {
  background-color: #00a096; }
  .btn:hover, .btn:focus, .btn:active {
    background-color: #26aea6; }
  .btn.white:hover, .btn.white:focus, .btn.white:active {
    background-color: #f5f5f5 !important; }
  .btn.pmb-green:hover, .btn.pmb-green:focus, .btn.pmb-green:active {
    background-color: #a7c931 !important; }
  .btn.grey.darken-3:hover, .btn.grey.darken-3:focus, .btn.grey.darken-3:active {
    background-color: #616161 !important; }

nav {
  background-color: rgba(255, 255, 255, 0);
  background-color: transparent; }

.footer-nav li:hover {
  background-color: rgba(255, 255, 255, 0.25); }

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    body {
      display: block;
      min-height: 0; } }

.btn, .btn-flat, .btn-large {
  text-transform: none;
  border-radius: 0.25rem;
  padding: 0 1rem; }

.btn, .btn-floating, .btn-large, .card, .card-panel, .collapsible, .dropdown-content, .side-nav, .z-depth-1, nav {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important; }

.btn-floating:hover, .btn-large:hover, .btn:hover, .z-depth-1-half {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important; }

.z-depth-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important; }

.main-content {
  flex: 1 0 auto;
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */ }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .main-content {
      flex: none; } }

.main-footer-copyright {
  flex-shrink: 0;
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */ }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .main-footer-copyright {
      flex: none; } }

.main-section {
  background-color: white; }
  .main-section-inner {
    padding: 3rem 1rem; }
    @media (min-width: 62em) {
      .main-section-inner {
        padding: 6rem 3rem; } }

.main-nav {
  position: fixed;
  top: 0;
  z-index: 995; }
  .main-nav-menu--mobile {
    z-index: 999;
    border-radius: 2px; }
    .main-nav-menu--mobile li {
      float: none; }
      .main-nav-menu--mobile li.active a:not(.btn) {
        font-weight: bold;
        color: black;
        background-color: rgba(0, 0, 0, 0.1); }
      .main-nav-menu--mobile li a:not(.btn) {
        display: inline-block;
        padding: 0 1rem;
        color: rgba(0, 0, 0, 0.5);
        background-color: transparent;
        transition: color 0.3s ease, background-color 0.3s ease;
        white-space: nowrap; }
  .main-nav .nav-wrapper {
    padding-left: 1rem;
    padding-right: 1rem; }
    @media (max-width: 62em) {
      .main-nav .nav-wrapper {
        display: flex; } }
    @media (min-width: 75em) {
      .main-nav .nav-wrapper {
        padding-left: 3rem;
        padding-right: 3rem; } }
    .main-nav .nav-wrapper ul {
      line-height: 2.5; }
    .main-nav .nav-wrapper li i {
      height: auto;
      line-height: inherit; }
    .main-nav .nav-wrapper li a:not(.btn) {
      color: rgba(0, 0, 0, 0.5); }
    .main-nav .nav-wrapper li.active a:not(.btn) {
      font-weight: bold;
      color: black; }
  .main-nav-mobile-section {
    position: absolute;
    right: 0; }
    @media (max-width: 36em) {
      .main-nav-mobile-section .button-nav-collapse {
        margin: 0 5px !important; } }
    .main-nav-mobile-section .main-nav-action {
      margin: 0 5px !important; }
  .main-nav .brand-logo {
    font-size: 0;
    position: static;
    left: auto;
    transform: translate(0, 0);
    margin: auto 0; }
    @media (min-width: 36em) and (max-width: 62em) {
      .main-nav .brand-logo {
        margin: auto; } }
    .main-nav .brand-logo img {
      margin: auto;
      padding: 0.5rem 0;
      width: auto;
      height: 100%; }

.hero {
  min-height: 150px;
  height: 50vw; }
  @media (min-width: 62em) {
    .hero {
      height: 40vw; } }
  .hero-title, .hero-text {
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25); }
  .hero-text {
    font-size: 15px; }
    @media (min-width: 576px) {
      .hero-text {
        font-size: calc(1.5625vw + 6px); } }
    @media (min-width: 768px) {
      .hero-text {
        font-size: calc(0vw + 18px); } }
    @media (min-width: 992px) {
      .hero-text {
        font-size: calc(1.92308vw - 1.07692px); } }
    @media (min-width: 1200px) {
      .hero-text {
        font-size: 22px; } }
  .hero-background {
    background-color: black;
    background-image: url("./../../images/hero-background-center-big.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left; }
    @media (min-width: 62em) {
      .hero-background {
        background-image: url("./../../images/hero-background-left.jpg");
        background-position: right bottom; } }

.main-hero-catchphrase {
  display: table;
  width: 25%;
  height: 100%;
  margin: -4% 52%; }
  .main-hero-catchphrase--mobile {
    background-color: #768e2c; }
    .main-hero-catchphrase--mobile h2 {
      white-space: nowrap; }
    .main-hero-catchphrase--mobile .hero-text {
      font-size: 14px;
      white-space: nowrap; }
      @media (min-width: 36em) {
        .main-hero-catchphrase--mobile .hero-text {
          font-size: 14pt; } }
    @media (max-width: 36em) {
      .main-hero-catchphrase--mobile .call-to-action--small {
        font-size: 11px; } }
  .main-hero-catchphrase-inner {
    display: table-cell;
    vertical-align: middle; }

.plane-ribbon {
  overflow: visible; }
  .plane-ribbon span {
    display: block;
    position: relative;
    /* allows us to position our pseudo-elements properly */
    background: #ff5722;
    overflow: visible;
    color: #fff;
    text-decoration: none;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.25); }
    .plane-ribbon span:before {
      /* this creates the "forked" part of our ribbon */
      content: "";
      height: 0;
      width: 0;
      display: block;
      position: absolute;
      top: 0; }
  .plane-ribbon--mobile {
    /* so we can see the pseudo-elements we're going to add to the anchor */
    font-size: 1rem;
    /* font-size and line-height must be equal so we can account for the height of the banner */
    line-height: 40px; }
    .plane-ribbon--mobile span {
      margin-left: 15px;
      padding-left: 10px;
      padding-right: 10px;
      height: 40px; }
      .plane-ribbon--mobile span:before {
        border-top: 20px solid #ff5722;
        border-bottom: 20px solid #ff5722;
        left: -15px;
        border-right: 15px solid transparent;
        border-left: 15px solid transparent; }
  .plane-ribbon--desktop {
    /* so we can see the pseudo-elements we're going to add to the anchor */
    font-size: 16px;
    /* font-size and line-height must be equal so we can account for the height of the banner */ }
    @media (min-width: 320px) {
      .plane-ribbon--desktop {
        font-size: calc(1.19048vw + 12.19048px); } }
    @media (min-width: 992px) {
      .plane-ribbon--desktop {
        font-size: 24px; } }
    .plane-ribbon--desktop span {
      margin-left: 29px;
      padding-left: 29px;
      padding-right: 29px;
      white-space: nowrap;
      height: 2.5rem; }
      @media (min-width: 62em) {
        .plane-ribbon--desktop span {
          height: 4vw; } }
      .plane-ribbon--desktop span:before {
        left: -29px;
        border-color: #ff5722 transparent;
        border-style: solid;
        border-width: 1.25rem 29px; }
        @media (min-width: 62em) {
          .plane-ribbon--desktop span:before {
            border-width: 2vw 29px; } }

@media (min-width: 62em) {
  .main-welcome-services-inner {
    position: relative;
    padding: 6rem 3rem; } }

.main-welcome-services .main-welcome {
  padding: 3rem 1rem; }
  @media (max-width: 62em) {
    .main-welcome-services .main-welcome {
      text-align: center; } }
  @media (min-width: 62em) {
    .main-welcome-services .main-welcome {
      position: absolute !important;
      top: -1px;
      bottom: 0;
      padding-top: 6rem;
      padding-bottom: 6rem; } }
  .main-welcome-services .main-welcome h2 {
    white-space: nowrap; }
    @media (min-width: 62em) {
      .main-welcome-services .main-welcome h2 {
        width: calc(100% + 150px); } }
  @media (min-width: 62em) {
    .main-welcome-services .main-welcome h3 {
      width: calc(100% + 100px); } }

@media (max-width: 62em) {
  .main-welcome-services .main-services {
    padding: 3rem 1rem; } }

.main-features .row {
  margin-left: -1rem !important;
  margin-right: -1rem !important; }

.main-interlude-inner {
  padding: 1rem; }
  @media (max-width: 48em) {
    .main-interlude-inner {
      text-align: center; } }
  @media (min-width: 62em) {
    .main-interlude-inner {
      padding: 3rem; } }
  .main-interlude-inner--left {
    white-space: nowrap; }
    @media (max-width: 48em) {
      .main-interlude-inner--left {
        margin-bottom: 1rem; } }
    @media (min-width: 48em) {
      .main-interlude-inner--left {
        float: left;
        transform: translateY(50%); } }
  @media (min-width: 48em) {
    .main-interlude-inner--right {
      float: right; } }

@media (min-width: 62em) {
  .main-specs-newsletter-form-inner {
    position: relative;
    padding: 6rem 3rem; } }

@media (min-width: 62em) and (max-width: 75em) {
  .main-specs-newsletter-form-inner {
    height: 760px; } }

@media (min-width: 75em) and (max-width: 1350px) {
  .main-specs-newsletter-form-inner {
    height: 700px; } }

.main-specs-newsletter-form .main-specs {
  padding: 3rem 1rem; }
  @media (max-width: 62em) {
    .main-specs-newsletter-form .main-specs {
      text-align: center; } }
  @media (min-width: 62em) {
    .main-specs-newsletter-form .main-specs {
      position: absolute !important;
      top: 0;
      bottom: 0;
      padding-top: 6rem;
      padding-bottom: 6rem; } }
  .main-specs-newsletter-form .main-specs h2 {
    white-space: nowrap; }
    @media (min-width: 62em) {
      .main-specs-newsletter-form .main-specs h2 {
        width: calc(100% + 150px); } }
  @media (min-width: 62em) {
    .main-specs-newsletter-form .main-specs-text {
      width: calc(100% + 100px); } }
  @media (min-width: 62em) and (max-width: 75em) {
    .main-specs-newsletter-form .main-specs-list {
      margin-right: -50px; } }
  @media (min-width: 62em) {
    .main-specs-newsletter-form .main-specs-list {
      margin-right: -75px; } }
  @media (max-width: 62em) {
    .main-specs-newsletter-form .main-specs-list ul {
      margin: 0; } }
  @media (max-width: 62em) {
    .main-specs-newsletter-form .main-specs-list ul li {
      padding: 0; } }
  .main-specs-newsletter-form .main-specs-list h5 {
    white-space: nowrap;
    font-size: 14.4px; }
    @media (min-width: 576px) {
      .main-specs-newsletter-form .main-specs-list h5 {
        font-size: calc(1.875vw + 3.6px); } }
    @media (min-width: 768px) {
      .main-specs-newsletter-form .main-specs-list h5 {
        font-size: calc(0vw + 18px); } }
    @media (min-width: 1200px) {
      .main-specs-newsletter-form .main-specs-list h5 {
        font-size: calc(2.4vw - 10.8px); } }
    @media (min-width: 1300px) {
      .main-specs-newsletter-form .main-specs-list h5 {
        font-size: 20.4px; } }

@media (max-width: 62em) {
  .main-specs-newsletter-form .main-newsletter-form {
    padding: 3rem 1rem; } }

@media (min-width: 36em) {
  .main-news-item {
    display: table; } }

@media (max-width: 36em) {
  .main-news-picture {
    height: 200px; } }

@media (min-width: 36em) {
  .main-news-picture {
    display: table-cell; } }

@media (min-width: 36em) and (max-width: 48em) {
  .main-news-picture {
    width: 50%; } }

@media (min-width: 48em) and (max-width: 62em) {
  .main-news-picture {
    width: calc(100% / 3); } }

@media (min-width: 62em) {
  .main-news-picture {
    width: 25%; } }

@media (max-width: 36em) {
  .main-news-picture img {
    width: auto;
    height: auto;
    max-width: 100%;
    min-width: 0 !important;
    min-height: 0 !important; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .main-news-picture img.media-cover {
    top: auto;
    left: auto;
    transform: none; } }

@media (min-width: 36em) {
  .main-news-content {
    display: table-cell; } }

.main-footer .row {
  margin-left: -1rem !important;
  margin-right: -1rem !important; }

@media (max-width: 48em) {
  .main-footer .main-footer-list {
    padding-left: 1rem;
    padding-right: 1rem; } }

@media (max-width: 48em) {
  .main-footer .main-footer-list > li:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    padding: 1rem 0; } }

.main-copyright-inner {
  padding-left: 1rem;
  padding-right: 1rem; }
  @media (min-width: 62em) {
    .main-copyright-inner {
      padding-left: 3rem;
      padding-right: 3rem; } }

@media (max-width: 48em) {
  .main-copyright--left {
    display: flex;
    height: 56px; } }

@media (min-width: 48em) {
  .main-copyright--left {
    float: left; } }

@media (max-width: 48em) {
  .main-copyright-content {
    margin: auto;
    line-height: 1.5; } }

@media (max-width: 48em) {
  .main-copyright--right {
    display: flex; } }

@media (min-width: 48em) {
  .main-copyright--right {
    float: right; } }

@media (max-width: 420px) {
  .main-copyright li a {
    font-size: 12px; } }

@media screen and (min-width: 0\0) and (min-resolution: 72dpi) {
  .main-hero--mobile h2 {
    white-space: normal !important;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word; }
  .main-hero--mobile .hero-text {
    white-space: normal !important;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word; }
  .main-interlude-inner--left h3 {
    white-space: normal !important;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word; }
  .main-welcome h2 h2, .main-welcome h2-list,
  .main-specs h2,
  .main-specs-list {
    white-space: normal !important;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word; }
  .main-copyright {
    height: auto; }
    .main-copyright-inner {
      height: auto; } }

@supports (-ms-ime-align: auto) {
  .main-hero--mobile h2 {
    white-space: normal !important;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word; }
  .main-hero--mobile .hero-text {
    white-space: normal !important;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word; }
  .main-interlude-inner--left h3 {
    white-space: normal !important;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word; }
  .main-welcome h2 h2, .main-welcome h2-list,
  .main-specs h2,
  .main-specs-list {
    white-space: normal !important;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word; }
  .main-copyright {
    height: auto; }
    .main-copyright-inner {
      height: auto; } }
