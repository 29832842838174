/*----------------------------------------------*\
        $BASE
\*----------------------------------------------*/

*:focus {
    outline: none !important;
}

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
}

body {
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
}

strong {
    font-weight: bold;
}

a {
    color: currentColor;
    text-decoration: none;
    transition: color 0.3s ease, background-color 0.3s ease;
    &:visited,
    &:hover,
    &:focus,
    &:active {
        color: currentColor;
        text-decoration: none;
        transition: color 0.3s ease, background-color 0.3s ease;
    }
}

i {
    font-style: normal;
}

button,
input,
optgroup,
select,
textarea,
label {
    font-family: 'Roboto', sans-serif;
}

button,
input,
optgroup,
select,
textarea {
    color: initial;
}

figure {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    margin: 0;
}


/* Remove controls from Firefox */

input[type=number] {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
        /* Removes leftover margin */
    }
}


/* Remove controls from Safari and Chrome */


/* Opera right align fix */

html:not([dir="rtl"]) input {
    text-align: left;
}

ul {
    margin: 0;
    color: #7b7b7b;
}

ul.browser-default {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
}

address {
    color: #7b7b7b;
}