// fix footer to bottom of body
body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block;
        min-height: 0;
    }
}
.btn, .btn-flat, .btn-large {
    text-transform: none;
    border-radius: 0.25rem;
    padding: 0 1rem;
}
.btn, .btn-floating, .btn-large, .card, .card-panel, .collapsible, .dropdown-content, .side-nav, .z-depth-1, nav {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
                0 2px 10px 0 rgba(0, 0, 0, 0.12)!important;
}
.btn-floating:hover, .btn-large:hover, .btn:hover, .z-depth-1-half {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
                0 4px 15px 0 rgba(0, 0, 0, 0.15)!important;
}
.z-depth-2 {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
                0 6px 20px 0 rgba(0, 0, 0, 0.19)!important;
}

.main-content {
    flex: 1 0 auto;
    /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        flex: none;
    }
}

.main-footer-copyright {
    flex-shrink: 0;
    /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        flex: none;
    }
}



.main-section {
    background-color: white;

    &-inner {
        padding: 3rem 1rem;
        @media (min-width: $mq-desktop) {
            padding: 6rem 3rem;
        }
    }
}

.main-nav {
    position: fixed;
    top: 0;
    z-index: 995;
    &-menu--mobile {
        z-index: 999;
        border-radius: 2px;
        li {
            float: none;
            &.active a:not(.btn) {
                font-weight: bold;
                color: black;
                background-color: rgba(0, 0, 0, 0.1);
            }
            a:not(.btn) {
                display: inline-block;
                padding: 0 1rem;
                color: rgba(0, 0, 0, 0.5);
                background-color: transparent;
                transition: color 0.3s ease, background-color 0.3s ease;
                white-space: nowrap;
            }
        }
    }
    .nav-wrapper {
        padding-left: 1rem;
        padding-right: 1rem;
        @media (max-width: $mq-desktop) {
            display: flex;
        }
        @media (min-width: $mq-large-desktop) {
            padding-left: 3rem;
            padding-right: 3rem;
        }

        ul {
            line-height: 2.5;
        }

        li i {
            height: auto;
            line-height: inherit;
        }

        li a:not(.btn) {
            color: rgba(0, 0, 0, 0.5);
        }
        li.active a:not(.btn) {
            font-weight: bold;
            color: black;
        }
    }
    &-mobile-section {
        position: absolute;
        right: 0;

        & .button-nav-collapse {
            @media (max-width: $mq-mobile) {
                margin: 0 5px !important;
            }
        }
        & .main-nav-action {
            margin: 0 5px !important;
        }
    }
    .brand-logo {
        font-size: 0;
        //@media (max-width: $mq-desktop) {
            position: static;
            left: auto;
            transform: translate(0, 0);
            margin: auto 0;
        //}
        @media (min-width: $mq-mobile) and (max-width: $mq-desktop) {
            margin: auto;
        }
        img {
            margin: auto;
            padding: 0.5rem 0;
            width: auto;
            height: 100%;
        }
    }
}

.hero {
    min-height: 150px;
    height: 50vw;
    @media (min-width: $mq-desktop) {
        height: 40vw;
    }

    &-title,
    &-text {
        text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    }

    &-text {
        $map: (576px: 15px, 768px: 18px, 992px: 18px, 1200px: 22px);
        @include poly-fluid-sizing('font-size', $map);
    }

    &-background {
        background-color: black;
        background-image: url("./../../images/hero-background-center-big.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top left;
        @media (min-width: $mq-desktop) {
            background-image: url("./../../images/hero-background-left.jpg");
            background-position: right bottom;
        }
    }
}

.main-hero {

    &-catchphrase {
        display: table;
        width: 25%;
        height: 100%;
        margin: -4% 52%;

        &--mobile {
            //background-color: #8ccfe0;
            background-color: #768e2c;

            h2 {
                white-space: nowrap;
            }

            .hero-text {
                font-size: 14px;
                white-space: nowrap;
                @media (min-width: $mq-mobile) {
                    font-size: 14pt;
                }
            }

            .call-to-action--small {
                @media (max-width: $mq-mobile) {
                    font-size: 11px;
                }
            }
        }

        &-inner {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

.plane-ribbon {
    overflow: visible;

    span {
        display: block;
        position: relative;
        /* allows us to position our pseudo-elements properly */
        background: #ff5722;
        overflow: visible;
        color: #fff;
        text-decoration: none;
        text-shadow: 0 0 2px rgba(0,0,0,.25);

        &:before {
            /* this creates the "forked" part of our ribbon */
            content: "";
            height: 0;
            width: 0;
            display: block;
            position: absolute;
            top: 0;
        }
    }

    &--mobile {
        /* so we can see the pseudo-elements we're going to add to the anchor */
        font-size: 1rem;
        /* font-size and line-height must be equal so we can account for the height of the banner */
        line-height: 40px;

        span {
            margin-left: 15px;
            padding-left: 10px;
            padding-right: 10px;
            height: 40px;

            &:before {
                border-top: 20px solid #ff5722;
                border-bottom: 20px solid #ff5722;
                left: -15px;
                border-right: 15px solid transparent;
                border-left: 15px solid transparent;
            }
        }
    }

    &--desktop {
        /* so we can see the pseudo-elements we're going to add to the anchor */
        $map: (320px: 16px, 992px: 24px);
        @include poly-fluid-sizing('font-size', $map);
        //font-size: 1.5vw;
        /* font-size and line-height must be equal so we can account for the height of the banner */
        //line-height: 4vw;

        span {
            margin-left: 29px;
            padding-left: 29px;
            padding-right: 29px;
            white-space: nowrap;
            height: 2.5rem;
            @media (min-width: $mq-desktop) {
                height: 4vw;
            }

            &:before {
                left: -29px;
                border-color: #ff5722 transparent;
                border-style: solid;
                border-width: 1.25rem 29px;

                @media (min-width: $mq-desktop) {
                    border-width: 2vw 29px;
                }
            }
        }
    }

}

.main-welcome-services {
    &-inner {
        @media (min-width: $mq-desktop) {
            position: relative;
            padding: 6rem 3rem;
        }
    }
    .main-welcome {
        padding: 3rem 1rem;
        @media (max-width: $mq-desktop) {
            text-align: center;
        }
        @media (min-width: $mq-desktop) {
            position: absolute !important;
            top: -1px;
            bottom: 0;
            padding-top: 6rem;
            padding-bottom: 6rem;
        }

        h2 {
            white-space: nowrap;

            @media (min-width: $mq-desktop) {
                width: calc(100% + 150px);
            }
        }
        h3 {
            @media (min-width: $mq-desktop) {
                width: calc(100% + 100px);
            }
        }
    }
    .main-services {
        @media (max-width: $mq-desktop) {
            padding: 3rem 1rem;
        }
    }
}

.main-features {
    .row {
        margin-left: -1rem !important;
        margin-right: -1rem !important;
    }
}

.main-interlude {
    &-inner {
        padding: 1rem;
        @media (max-width: $mq-tablet) {
            text-align: center;
        }
        @media (min-width: $mq-desktop) {
            padding: 3rem;
        }

        &--left {
            white-space: nowrap;

            @media (max-width: $mq-tablet) {
                margin-bottom: 1rem;
            }
            @media (min-width: $mq-tablet) {
                float: left;
                transform: translateY(50%);
            }
        }

        &--right {
            @media (min-width: $mq-tablet) {
                float: right;
            }
        }
    }
}

.main-specs-newsletter-form {
    &-inner {
        @media (min-width: $mq-desktop) {
            position: relative;
            padding: 6rem 3rem;
        }
        @media (min-width: $mq-desktop) and (max-width: $mq-large-desktop) {
            height: 760px;
        }
        @media (min-width: $mq-large-desktop) and (max-width: 1350px) {
            height: 700px;
        }
    }

    .main-specs {
        padding: 3rem 1rem;
        @media (max-width: $mq-desktop) {
            text-align: center;
        }
        @media (min-width: $mq-desktop) {
            position: absolute !important;
            top: 0;
            bottom: 0;
            padding-top: 6rem;
            padding-bottom: 6rem;
        }

        h2 {
            white-space: nowrap;

            @media (min-width: $mq-desktop) {
                width: calc(100% + 150px);
            }
        }
        &-text {
            @media (min-width: $mq-desktop) {
                width: calc(100% + 100px);
            }
        }

        &-list {
            @media (min-width: $mq-desktop) and (max-width: $mq-large-desktop) {
                margin-right: -50px;
            }
            @media (min-width: $mq-desktop) {
                margin-right: -75px;
            }
            ul {
                @media (max-width: $mq-desktop) {
                    margin: 0;
                }
                li {
                    @media (max-width: $mq-desktop) {
                        padding: 0;
                    }
                }
            }

            h5 {
                white-space: nowrap;
                $map: (576px: 14.4px, 768px: 18px, 1200px: 18px, 1300px: 20.4px);
                @include poly-fluid-sizing('font-size',
                $map);
            }
        }
    }
    .main-newsletter-form {
        @media (max-width: $mq-desktop) {
            padding: 3rem 1rem;
        }
    }
}

.main-news {
    &-item {
        @media (min-width: $mq-mobile) {
            display: table;
        }
    }
    &-picture {
        @media (max-width: $mq-mobile) {
            height: 200px;
        }
        @media (min-width: $mq-mobile) {
            display: table-cell;
        }
        @media (min-width: $mq-mobile) and (max-width: $mq-tablet) {
            width: 50%;
        }
        @media (min-width: $mq-tablet) and (max-width: $mq-desktop) {
            width: calc(100% / 3);
        }
        @media (min-width: $mq-desktop) {
            width: 25%;
        }

        img {
            @media (max-width: $mq-mobile) {
                width: auto;
                height: auto;
                max-width: 100%;
                min-width: 0 !important;
                min-height: 0 !important;
            }
            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                &.media-cover {
                    top: auto;
                    left: auto;
                    transform: none;
                }
            }
        }
    }
    &-content {
        @media (min-width: $mq-mobile) {
            display: table-cell;
        }
    }
}

.main-footer {
    .row {
        margin-left: -1rem !important;
        margin-right: -1rem !important;
    }

    .main-footer-list {
        @media (max-width: $mq-tablet) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        &>li {
            @media (max-width: $mq-tablet) {
                &:not(:first-child) {
                    border-top: 1px solid rgba(255, 255, 255, 0.25);
                    padding: 1rem 0;
                }
            }
        }
    }
}

.main-copyright {
    //height: 100%;

    &-inner {
        padding-left: 1rem;
        padding-right: 1rem;
        @media (min-width: $mq-desktop) {
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }

    &--left {
        @media (max-width: $mq-tablet) {
            display: flex;
            height: 56px;
        }
        @media (min-width: $mq-tablet) {
            float: left;
        }
    }

    &-content {
        @media (max-width: $mq-tablet) {
            margin: auto;
            line-height: 1.5;
        }
    }

    &--right {
        @media (max-width: $mq-tablet) {
            display: flex;
        }
        @media (min-width: $mq-tablet) {
            float: right;
        }
    }
    li a {
        @media (max-width: 420px) {
            font-size: 12px;
        }
    }
}

@media screen and (min-width:0\0) and (min-resolution:+72dpi) {
    .main-hero--mobile {
        h2 {
            white-space: normal !important;

            /* These are technically the same, but use both */
            overflow-wrap: break-word;
            word-wrap: break-word;

            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;
        }
        .hero-text {
            white-space: normal !important;

            /* These are technically the same, but use both */
            overflow-wrap: break-word;
            word-wrap: break-word;

            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;
        }
    }
    .main-interlude-inner--left h3 {
        white-space: normal !important;

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
    }
    .main-welcome h2,
    .main-specs {
        h2,
        &-list {
            white-space: normal !important;

            /* These are technically the same, but use both */
            overflow-wrap: break-word;
            word-wrap: break-word;

            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;
        }
    }
    .main-copyright {
        height: auto;

        &-inner {
            height: auto;
        }
    }
}

@supports (-ms-ime-align:auto) {
    .main-hero--mobile {
        h2 {
            white-space: normal !important;

            /* These are technically the same, but use both */
            overflow-wrap: break-word;
            word-wrap: break-word;

            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;
        }
        .hero-text {
            white-space: normal !important;

            /* These are technically the same, but use both */
            overflow-wrap: break-word;
            word-wrap: break-word;

            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;
        }
    }
    .main-interlude-inner--left h3 {
        white-space: normal !important;

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
    }
    .main-welcome h2,
    .main-specs {
        h2,
        &-list {
            white-space: normal !important;

            /* These are technically the same, but use both */
            overflow-wrap: break-word;
            word-wrap: break-word;

            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;
        }
    }
    .main-copyright {
        height: auto;

        &-inner {
            height: auto;
        }
    }
}