/*----------------------------------------------*\
        $Override Materialize
\*----------------------------------------------*/

.btn {
    background-color: #00a096;
    // pmb-duck
    &:hover,
    &:focus,
    &:active {
        background-color: #26aea6;
        // pmb-duck lighten-1
    }
    &.white {
        &:hover,
        &:focus,
        &:active {
            background-color: #f5f5f5 !important;
            // grey lighten-4
        }
    }
    &.pmb-green {
        &:hover,
        &:focus,
        &:active {
            background-color: #a7c931 !important;
            // pmb-green lighten-1
        }
    }
    &.grey.darken-3 {
        &:hover,
        &:focus,
        &:active {
            background-color: #616161 !important;
            // grey darken-2
        }
    }
}

nav {
    background-color: rgba(255, 255, 255, 0);
    background-color: transparent;
}

.footer-nav li:hover {
    background-color: rgba(255, 255, 255, 0.25);
}
